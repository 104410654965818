import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BRIEF_API,
  CLIENT_API,
  CLIENT_BRIEF_MESSAGE_API,
} from "../../constants/api";
import socketIOClient from "socket.io-client";
import "./Activity.css";
import { format } from "date-fns";
import Picker from "emoji-picker-react";
import { toast } from "react-toastify";
import { Badge } from "reactstrap";

function Activity() {
  const chatContainerRef = useRef(null);
  const { projectId, briefId } = useParams();
  const [project, setProject] = useState([]);
  const [order, setOrder] = useState(null);
  const [messages, setMessages] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const myId = localStorage.getItem("userId");
  const myID = localStorage.getItem("userID");
  const [leadId, setLeadId] = useState("");
  const [clientId, setClientId] = useState("");
  const [update, setUpdate] = useState(0);
  const [file, setFile] = useState("");
  const [socket, setSocket] = useState(null);
  const fileRef = useRef("");
  const [loading, setLoading] = useState(false);
  const scrollableDivRef = useRef(null);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const deliverModalCloseButton = useRef(null);
  const [status, setStatus] = useState("");
  const [comments, setComments] = useState("");
  const [weTransferLink, setWeTransferLink] = useState("");

  const [timeLeft, setTimeLeft] = useState({});
  const [defaultStatus, setDefaultStatus] = useState("");

  // This function will scroll the chat container to the bottom
  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  // Function to calculate time left for each project
  const calculateTimeLeft = (createdAt, duration) => {
    const targetDate = new Date(createdAt);
    targetDate.setHours(targetDate.getHours() + duration); // Add duration in hours
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  // Update time left for all projects
  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = project.reduce((acc, proj) => {
        acc[proj._id] = calculateTimeLeft(
          proj.startDate,
          parseInt(proj.title.time)
        );
        return acc;
      }, {});
      setTimeLeft(updatedTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [project]);

  // Socket.io
  useEffect(() => {
    const socket = socketIOClient("https://api-v2.hype-x.ca");
    setSocket(socket);

    if(myId){
      socket.emit("storeSocketId", myId);
    }else if(myID){
      socket.emit("storeSocketId", myID);
    }
   

    socket.on("receive_client_brief_message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const onEmojiClick = (e) => {
    console.log(e);
    setNewMessage((prev) => prev + e.emoji);
    setShowPicker(false);
  };
  useEffect(() => {
    const getMessages = async () => {
      const response = await fetch(
        CLIENT_BRIEF_MESSAGE_API + `/${projectId}/${briefId}`
      );
      const json = await response.json();

      if (response.ok) {
        setMessages(json.data);
      }
    };

    const getBriefs = async () => {
      const response = await fetch(BRIEF_API + `${projectId}/${briefId}`);
      const json = await response.json();
      if (response.ok) {
        setProject(json.data ? [json.data] : []);
        setStatus(json.data.status);
        setTitle(json.data.title);
        setDescription(json.data.description);

        setClientId(json.data.createdBy);
      } else {
        // Handle error response
        console.error("Error fetching project:", json);
      }
    };
    const getOrder = async () => {
      const response = await fetch(CLIENT_API + "/single/" + projectId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        setOrder(json.data ? [json.data] : []);
      } else {
        // Handle error response
        console.error("Error fetching project:", json);
      }
    };

    getBriefs();
    getOrder();
    getMessages();
  }, [briefId, token, update]);

  const handleAttachFile = () => {
    fileRef.current.click();
  };
  const handleImageChange = async (e) => {
    setFile(e.target.files[0]);
    setNewMessage(e.target.files[0].name);
  };

  const handleSend = async () => {


    if (newMessage.trim() !== "") {
      // setLoading(true);
      setLoading(true);
      const formData = new FormData();

      if (role === "client") {
        formData.append("senderId", myId);
        // formData.append("receiverId", leadId);
      } else {
        formData.append("senderId", myID);
        // formData.append("receiverId", clientId);
      }

      formData.append("clientProjectId", projectId);
      formData.append("briefId", briefId);

      if (file) {
        formData.append("file", file);
      } else {
        if (newMessage.trim() !== "") {
          formData.append("message", newMessage);
        }
      }
      try {
        const response = await fetch(CLIENT_BRIEF_MESSAGE_API + "/send", {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          console.log("message sent");
        }
      } catch (error) {
      } finally {
        setNewMessage("");
        setUpdate(Math.random());
        setFile("");
        setLoading(false);
      }
    }
  };

  const handleClickFaq = () => {
    navigate("/");
  };
  // useEffect(() => {
  //   if (scrollableDivRef.current) {
  //     scrollableDivRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   }
  // }, [messages]);

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    if (status.trim() !== "") {
      const action = "update-status";
      const statusValues = {
        action,
        status,
        comments,
      };
      const response = await fetch(BRIEF_API + "/updateStatus/" + briefId, {
        method: "PUT",
        body: JSON.stringify(statusValues),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success("Status Updated");
        setUpdate(Math.random());
      }
    }
  };
  const updateBrief = async (e) => {
    e.preventDefault();

    const action = "update-data";
    const statusValues = {
      action,
      description,
    };
    const response = await fetch(BRIEF_API + "/updateStatus/" + briefId, {
      method: "PUT",
      body: JSON.stringify(statusValues),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      toast.success("Brief Updated");
      setUpdate(Math.random());
    }
  };

  const deliverBrief = async (e) => {
    e.preventDefault();

    if (weTransferLink.trim() === "") {
      toast.error("WeTransfer link required!!!");
    } else {
      const link = await weTransferLink;
      const data = {
        link,
      };
      const response = await fetch(BRIEF_API + "/deliver/" + briefId, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const json = await response.json();
        toast.success("Brief delivered successfully!!!");
        setUpdate(Math.random());
        deliverModalCloseButton.current.click();
        // setDefaultStatus(json.data.status)
      }
    }
  };
  return (
    <div className="col-md-12">
      {project.map((proj) => (
        <div className="row" key={proj._id}>
          <div className="col-12">
            <div className="card w-100 shadow card-b p-4">
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-3">
                      {" "}
                      <strong>Brief Title:</strong>
                    </div>
                    <div className="col-md-9">
                      <p>{proj.title.name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-3">
                      {" "}
                      <strong>Brief Description:</strong>
                    </div>
                    <div className="col-md-9">
                      <p>{proj.description}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#briefModal"
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    Edit
                  </button>

                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="briefModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            Update Brief
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body">
                          <form className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="inputEmail4"
                                className="form-label"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                onChange={(e) => setDescription(e.target.value)}
                                id="exampleFormControlTextarea1"
                                rows={8}
                                value={description}
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          {/* <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button> */}
                          <button
                            onClick={updateBrief}
                            type="button"
                            className="btn"
                            style={{ backgroundColor: "black", color: "white" }}
                            data-bs-dismiss="modal"
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12">
                <div className="custom-card mb-3 card card-b">
                  <div className="custom-card-body">
                    <h5 className="custom-title">User Timeline</h5>
                    <div className="custom-scroll-area">
                      <div className="custom-vertical-timeline custom-vertical-timeline--animate custom-vertical-timeline--one-column">
                        {/* this  */}
                        {proj.timeline.map((time) => (
                          <div
                            key={time._id}
                            className="custom-vertical-timeline-item custom-vertical-timeline-element"
                          >
                            <div>
                              <span className="custom-vertical-timeline-element-icon bounce-in">
                                <i className="custom-badge custom-badge-dot custom-badge-dot-xl custom-badge-success">
                                  {" "}
                                </i>
                              </span>
                              <div className="custom-vertical-timeline-element-content bounce-in">
                                <h4 className="custom-timeline-title">
                                  {time.title}
                                </h4>
                                <p>
                                  {time.description ? (
                                    <>{time.description.slice(0, 100)}</>
                                  ) : (
                                    <></>
                                  )}{" "}
                                  {/* {time.updatedBy ? (
                                    <span className="text-capitalize">
                                      {" "}
                                      ({time.updatedBy})
                                    </span>
                                  ) : (
                                    <></>
                                  )} */}
                                  {/* <a href="javascript:void(0);" data-abc="true">
                                    10:00 AM
                                  </a> */}
                                </p>
                                <span className="custom-vertical-timeline-element-date">
                                  {format(new Date(time.createdAt), "hh:mm a")}
                                  <br />
                                  {format(
                                    new Date(time.createdAt),
                                    "dd MMM, yyyy"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}

                        {/* end this  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-b activity-inbox-card w-100 chat-app-client-brief">
                  <div className="chat-client">
                    <div className="chat-history-client" ref={chatContainerRef}>
                      <ul className="mb-0" ref={scrollableDivRef}>
                        {messages &&
                          messages.map((data) =>
                            data.senderId === myId || data.senderId === myID ? (
                              // my message

                              <li className="clearfix-client text-end">
                                <div className="message-data-client">
                                  <span className="message-data-client-time">
                                    {data.senderRole === "client"? data.senderName : "Hype X"} &nbsp;
                                    <img
                                      src="/media/user.png"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="message-client other-message-client">
                                  {data.message ? <>{data.message}</> : <></>}
                                  {data.file.length !== 0 ? (
                                    <>
                                      {data.file[0].secure_url
                                        .split(".")
                                        .pop()
                                        .toLowerCase() === "png" ||
                                      data.file[0].secure_url
                                        .split(".")
                                        .pop()
                                        .toLowerCase() === "jpg" ||
                                      data.file[0].secure_url
                                        .split(".")
                                        .pop()
                                        .toLowerCase() === "jpeg" ? (
                                        <>
                                          {" "}
                                          <img
                                            className=""
                                            style={{
                                              width: "100%",
                                              height: "250px",
                                            }}
                                            src={data.file[0].secure_url}
                                            alt=""
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <i className="bi bi-file-pdf-fill text-danger" />
                                          &nbsp;
                                          {data.file[0].fileName}
                                        </>
                                      )}

                                      <br />
                                      <a
                                        target="_blank"
                                        href={data.file[0].secure_url}
                                        download={data.file[0].secure_url}
                                      >
                                        Download
                                      </a>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="message-data-client">
                                  <span className="message-data-client-time">
                                    {format(
                                      new Date(data.createdAt),
                                      "dd MMM, yyyy hh:mm a"
                                    )}
                                  </span>
                                </div>
                              </li>
                            ) : (
                              // my message end
                              //  there message
                              <li className="clearfix-client">
                                <div className="message-data-client">
                                  <img
                                    src="/media/user.png"
                                    className="img-fluid"
                                    alt=""
                                  />{" "}
                                  &nbsp;{
                                    data.senderRole === "client"? data.senderName : "Hype X"
                                  }
                                 
                                </div>
                                <div className="message-client my-message-client">
                                  {data.message ? <>{data.message}</> : <></>}
                                  {data.file.length !== 0 ? (
                                    <>
                                      {data.file[0].secure_url
                                        .split(".")
                                        .pop()
                                        .toLowerCase() === "png" ||
                                      data.file[0].secure_url
                                        .split(".")
                                        .pop()
                                        .toLowerCase() === "jpg" ||
                                      data.file[0].secure_url
                                        .split(".")
                                        .pop()
                                        .toLowerCase() === "jpeg" ? (
                                        <>
                                          {" "}
                                          <img
                                            className=""
                                            style={{
                                              width: "100%",
                                              height: "250px",
                                            }}
                                            src={data.file[0].secure_url}
                                            alt=""
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <i className="bi bi-file-pdf-fill text-danger" />
                                          &nbsp;
                                          {data.file[0].fileName}
                                        </>
                                      )}

                                      <br />
                                      <a
                                        target="_blank"
                                        href={data.file[0].secure_url}
                                        download={data.file[0].secure_url}
                                      >
                                        Download
                                      </a>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="message-data-client">
                                  <span className="message-data-client-time">
                                    {format(
                                      new Date(data.createdAt),
                                      "dd MMM, yyyy hh:mm a"
                                    )}
                                  </span>
                                </div>
                              </li>
                              //  there message end
                            )
                          )}
                      </ul>
                      {showPicker && (
                        <div
                          className="float-right"
                          style={{
                            zIndex: "7",
                            position: "absolute",
                            right: "10px",
                            bottom: "50px",
                          }}
                        >
                          <Picker
                            pickerStyle={{ width: "100%", opacity: "0.1" }}
                            onEmojiClick={onEmojiClick}
                          />
                        </div>
                      )}

                      {/* <div ref={chatEndRef} /> */}
                    </div>

                    <div className="chat-message-client clearfix-client">
                      <div className="input-group mb-0">
                        <div role="button" className="input-group-prepend">
                          <span
                            onClick={() => setShowPicker((val) => !val)}
                            className="input-group-text"
                          >
                            <i class="bi bi-emoji-smile"></i>
                          </span>
                        </div>
                        <div role="button" className="input-group-prepend">
                          <span
                            onClick={handleAttachFile}
                            className="input-group-text"
                          >
                            <i class="bi bi-file-earmark-arrow-up"></i>
                          </span>
                          <input
                            ref={fileRef}
                            type="file"
                            onChange={handleImageChange}
                            className="form-control-file d-none"
                            id="exampleFormControlFile1"
                            accept="image/*,.pdf"
                          />
                        </div>

                        <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSend();
                            }
                          }}
                          id="client-message-input"
                          type="text"
                          className="form-control"
                          onChange={(e) => setNewMessage(e.target.value)}
                          placeholder="Enter text here..."
                          value={newMessage}
                        />
                        {loading === true ? (
                          <div className="input-group-prepend">
                            <span
                              className="spinner-border"
                              role="status"
                            ></span>
                          </div>
                        ) : (
                          <>
                            <div role="button" className="input-group-prepend">
                              <span
                                onClick={handleSend}
                                className="input-group-text"
                              >
                                <i class="bi bi-send"></i>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="card card-b activity-inbox-card w-100">
                  <div className="custom-title">Brief History</div>
                  <div className="px-5">
                    {proj.history &&
                      proj.history.map((history) => (
                        <div
                          className="row py-2 my-2"
                          style={{ backgroundColor: "#f1f1f1" }}
                        >
                          <div className="col-md-12">
                            {/* <hr /> */}
                            <div className="row">
                              <div className="col-md-3">
                                {" "}
                                <strong>Brief Description:</strong>
                              </div>
                              <div className="col-md-9">
                                <p>{history.description}</p>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                {" "}
                                Changed By:{" "}
                                <strong>
                                  {history.updatedBy === "client" ? (
                                    <>Client</>
                                  ) : (
                                    <>
                                      {order &&
                                        order.map(
                                          (o) =>
                                            o.projectCategory.name +
                                            " Department"
                                        )}
                                    </>
                                  )}
                                </strong>
                              </div>
                              <div className="col-md-6 text-end">
                                <p>
                                  {format(
                                    new Date(history.updatedAt),
                                    "dd MMM, yyyy hh:mm a"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-md-12 pb-0 time-left-section-brief">
                <div className="text-center card w-100 activity-card card-b">
                  <h6 className="text-start w-100 custom-title">
                    Time left to deliver
                  </h6>
                  {proj.status === "completed" ? (
                    <div className="time-frame">
                      <div>
                        <p className="time-frame-paragraph">-</p>
                        <p className="time-frame-paragraph">
                          Day{timeLeft[proj._id]?.days !== 1 ? "s" : ""}
                        </p>
                      </div>
                      <div>
                        <p className="time-frame-paragraph">-</p>
                        <p className="time-frame-paragraph">
                          Hour{timeLeft[proj._id]?.hours !== 1 ? "s" : ""}
                        </p>
                      </div>
                      <div>
                        <p className="time-frame-paragraph">-</p>
                        <p className="time-frame-paragraph">
                          Minute{timeLeft[proj._id]?.minutes !== 1 ? "s" : ""}
                        </p>
                      </div>
                      <div>
                        <p className="time-frame-paragraph">-</p>
                        <p className="time-frame-paragraph">
                          Second{timeLeft[proj._id]?.seconds !== 1 ? "s" : ""}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="time-frame">
                      <div>
                        <p className="time-frame-paragraph">
                          {timeLeft[proj._id]?.days || 0}
                        </p>
                        <p className="time-frame-paragraph">
                          Day{timeLeft[proj._id]?.days !== 1 ? "s" : ""}
                        </p>
                      </div>
                      <div>
                        <p className="time-frame-paragraph">
                          {timeLeft[proj._id]?.hours || 0}
                        </p>
                        <p className="time-frame-paragraph">
                          Hour{timeLeft[proj._id]?.hours !== 1 ? "s" : ""}
                        </p>
                      </div>
                      <div>
                        <p className="time-frame-paragraph">
                          {timeLeft[proj._id]?.minutes || 0}
                        </p>
                        <p className="time-frame-paragraph">
                          Minute{timeLeft[proj._id]?.minutes !== 1 ? "s" : ""}
                        </p>
                      </div>
                      <div>
                        <p className="time-frame-paragraph">
                          {timeLeft[proj._id]?.seconds || 0}
                        </p>
                        <p className="time-frame-paragraph">
                          Second{timeLeft[proj._id]?.seconds !== 1 ? "s" : ""}
                        </p>
                      </div>
                    </div>
                  )}

                  {role === "client" ? (
                    <></>
                  ) : (
                    <>
                      {proj.status === "completed" ? (
                        <button
                          className="btn mt-2 w-50 mx-auto"
                          style={{ backgroundColor: "grey", color: "white" }}
                        >
                          Delivered
                        </button>
                      ) : (
                        <button
                          className="btn mt-2 w-50 mx-auto"
                          data-bs-toggle="modal"
                          data-bs-target="#deliverModal"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Deliver Now
                        </button>
                      )}

                      <div
                        className="modal fade"
                        id="deliverModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        data-backdrop="static"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Deliver
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref={deliverModalCloseButton}
                              />
                            </div>
                            <div className="modal-body">
                              <form className="row g-3">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setWeTransferLink(e.target.value)
                                    }
                                    className="form-control"
                                    placeholder="Enter WeTransfer Link"
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={deliverBrief}
                                type="button"
                                className="btn"
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                }}
                              >
                                Deliver Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {role !== "client" ? (
                <div className="col-md-12 mt-4">
                  <div className="card card-b activity-card w-100">
                    <div className="custom-title">Status</div>
                    <form className="row g-3 px-4">
                      <div className="col-md-12">
                        <textarea
                          name=""
                          rows={3}
                          className="form-control"
                          placeholder="Comment... (Optional)"
                          id=""
                          onChange={(e) => setComments(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="inputState" className="form-label">
                          Change Status
                        </label>
                        <select
                          id="inputState"
                          className="form-select"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="pending">Pending</option>
                          <option value="in-progress">In Progress</option>
                          <option value="rejected">Rejected</option>
                          <option value="completed">Completed</option>
                          <option value="delayed">Delayed</option>
                          <option value="meeting">Meeting</option>
                          <option value="reopen">Re Open</option>
                        </select>
                      </div>

                      <div className="col-12 d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn"
                          style={{ backgroundColor: "black", color: "white" }}
                          onClick={handleChangeStatus}
                        >
                          Update Status
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-md-12 mt-4">
                    <div className="card card-b activity-card w-100">
                      <div className="custom-title">Status</div>
                      <form className="row g-3 px-4">
                        <div className="col-md-12 ps-3">
                          <Badge
                            className={`${
                              status === "completed" ? "bg-success" : "bg-dark"
                            }`}
                          >
                            {status}
                          </Badge>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}

              {/* {order && (
                <>
                  <div className="col-md-12 mt-4">
                    <div className="card card-b activity-card w-100">
                      <div className="custom-title">Order Details</div>
                      <div className="info">
                        <div className="row">
                          <div className="col-7">
                            <span id="heading">Date</span>
                            <br />
                            <span id="details">
                              {" "}
                              {format(new Date(proj.createdAt), "dd MMM, yyyy")}
                            </span>
                          </div>
                          <div className="col-5 pull-right">
                            <span id="heading">Order No.</span>
                            <br />
                            <span id="details">{proj._id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="pricing">
                        <div className="row">
                          <div className="col-8">
                            <span id="name">{order[0].projectTitle}</span>
                          </div>
                          <div className="col-4 column-3">
                            <span
                              style={{ textTransform: "uppercase" }}
                              id="price"
                            >
                              {order[0].currency} {order[0].price}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="total">
                        <div className="row">
                          <div className="col-6" />
                          <div className="col-6 column-3">
                            <big
                              className=""
                              style={{ textTransform: "uppercase" }}
                            >
                              {order[0].currency} {order[0].price}
                            </big>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-12 mt-4">
                <div className="card card-b activity-card w-100">
                  <div className="tracking">
                    <div className="custom-title">Tracking Order</div>
                  </div>
                  <div className="progress-track">
                    <ul id="progressbar">
                      <li className="step0 active " id="step1">
                        Ordered
                      </li>
                      <li className="step0 active text-center" id="step2">
                        Requirements Submitted
                      </li>
                      <li className="step0 active text-end" id="step3">
                        In Progress
                      </li>
                      <li className="step0 text-end" id="step4">
                        Delivered
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* {role === "client" ? (
                <>
                  <div className="col-12 mt-4">
                    <div className="card card-b activity-card w-100">
                      <div className="tracking">
                        <div className="custom-title">Support</div>
                      </div>
                      <div className="container-fluid">
                        <div className="row px-4">
                          <div
                            className="client-order-page-support-1"
                            role="button"
                            onClick={handleClickFaq}
                          >
                            <h6 className="client-order-page-support-heading">
                              <i className="bi bi-question-circle-fill"></i>
                              &nbsp;&nbsp;FAQs{" "}
                              <span className="float-right client-order-page-support-heading-arrow">
                                <i className="bi bi-arrow-right-circle"></i>
                              </span>{" "}
                            </h6>
                            <p className="client-order-page-support-paragraph">
                              {" "}
                              Find needed answers.
                            </p>
                          </div>
                        </div>
                        <div className="row px-4">
                          <Link
                            to={`/client-auth/client-resolution-center/${projectId}`}
                            className="client-order-page-support-2"
                            href=""
                          >
                            <h6 className="client-order-page-support-heading">
                              <i className="bi bi-house-door-fill"></i>
                              &nbsp;&nbsp;Resolution Center{" "}
                              <span className="float-right client-order-page-support-heading-arrow">
                                <i className="bi bi-arrow-right-circle"></i>
                              </span>
                            </h6>
                            <p className="client-order-page-support-paragraph">
                              {" "}
                              Resolve order issues.
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )} */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Activity;
