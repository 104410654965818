import React, { useState, useEffect, useRef } from "react";

import socketIOClient from "socket.io-client";

import { format } from "date-fns";
import Picker from "emoji-picker-react";
import { DOWNLOAD_ISSUE_MESSAGE_API, PAYMENT_ISSUE_MESSAGE_API } from "../../../constants/api";

function PaymentIssueMessage() {
  const chatContainerRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const myId = localStorage.getItem("userId");
  const [update, setUpdate] = useState(0);
  const [file, setFile] = useState("");
  const [socket, setSocket] = useState(null);
  const fileRef = useRef("");
  const [loading, setLoading] = useState(false);
  const scrollableDivRef = useRef(null);

  // This function will scroll the chat container to the bottom
  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Socket.io
  useEffect(() => {
    const socket = socketIOClient("https://api-v2.hype-x.ca");
    setSocket(socket);

    socket.emit("storeSocketId", myId);

    socket.on("receive_payment_issue_message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const onEmojiClick = (e) => {
    console.log(e);
    setNewMessage((prev) => prev + e.emoji);
    setShowPicker(false);
  };
  useEffect(() => {
    const getMessages = async () => {
      const response = await fetch(PAYMENT_ISSUE_MESSAGE_API + `/${myId}`);
      const json = await response.json();

      if (response.ok) {
        setMessages(json.data);
      }
    };

    getMessages();
  }, [myId, token, update]);

  const handleAttachFile = () => {
    fileRef.current.click();
  };
  const handleImageChange = async (e) => {
    setFile(e.target.files[0]);
    setNewMessage(e.target.files[0].name);
  };

  const handleSend = async () => {
   

    if (newMessage.trim() !== "") {
      // setLoading(true);
      setLoading(true);
      const formData = new FormData();

      formData.append("senderId", myId);

      formData.append("clientId", myId);
      formData.append("role", "client");

      if (file) {
        formData.append("file", file);
      } else {
        if (newMessage.trim() !== "") {
          formData.append("message", newMessage);
        }
      }
      try {
        const response = await fetch(PAYMENT_ISSUE_MESSAGE_API + "/send", {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          console.log("message sent");
        }
      } catch (error) {
      } finally {
        setNewMessage("");
        setUpdate(Math.random());
        setFile("");
        setLoading(false);
      }
    }
  };

  return (
    <div className="card w-100 chat-app-client-brief" style={{ backgroundColor: "#F2F7F8", boxShadow: "none" }}>
      <div className="chat-client">
        <div className="chat-history-client" ref={chatContainerRef}>
          <ul className="mb-0" ref={scrollableDivRef}>
            {messages &&
              messages.map((data) =>
                data.senderId === myId || data.senderId === myId ? (
                  // my message

                  <li className="clearfix-client text-end">
                    <div className="message-data-client">
                      <span className="message-data-client-time">
                        {data.senderName} &nbsp;
                        <img
                          src="/media/user.png"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="message-client other-message-client" style={{backgroundColor: "#DEFFDE"}}>
                      {data.message ? <>{data.message}</> : <></>}
                      {data.file.length !== 0 ? (
                        <>
                          {data.file[0].secure_url
                            .split(".")
                            .pop()
                            .toLowerCase() === "png" ||
                          data.file[0].secure_url
                            .split(".")
                            .pop()
                            .toLowerCase() === "jpg" ||
                          data.file[0].secure_url
                            .split(".")
                            .pop()
                            .toLowerCase() === "jpeg" ? (
                            <>
                              {" "}
                              <img
                                className=""
                                style={{
                                  width: "100%",
                                  height: "250px",
                                }}
                                src={data.file[0].secure_url}
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <i className="bi bi-file-pdf-fill text-danger" />
                              &nbsp;
                              {data.file[0].fileName}
                            </>
                          )}

                          <br />
                          <a
                            target="_blank"
                            href={data.file[0].secure_url}
                            download={data.file[0].secure_url}
                          >
                            Download
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="message-data-client">
                      <span className="message-data-client-time">
                        {format(
                          new Date(data.createdAt),
                          "dd MMM, yyyy hh:mm a"
                        )}
                      </span>
                    </div>
                  </li>
                ) : (
                  // my message end
                  //  there message
                  <li className="clearfix-client">
                    <div className="message-data-client">
                      <img src="/media/user.png" className="img-fluid" alt="" />{" "}
                      &nbsp;
                      {/* {data.senderName} */}
                      Hype X
                    </div>
                    <div className="message-client my-message-client" style={{backgroundColor: "#DEF0FF"}}>
                      {data.message ? <>{data.message}</> : <></>}
                      {data.file.length !== 0 ? (
                        <>
                          {data.file[0].secure_url
                            .split(".")
                            .pop()
                            .toLowerCase() === "png" ||
                          data.file[0].secure_url
                            .split(".")
                            .pop()
                            .toLowerCase() === "jpg" ||
                          data.file[0].secure_url
                            .split(".")
                            .pop()
                            .toLowerCase() === "jpeg" ? (
                            <>
                              {" "}
                              <img
                                className=""
                                style={{
                                  width: "100%",
                                  height: "250px",
                                }}
                                src={data.file[0].secure_url}
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <i className="bi bi-file-pdf-fill text-danger" />
                              &nbsp;
                              {data.file[0].fileName}
                            </>
                          )}

                          <br />
                          <a
                            target="_blank"
                            href={data.file[0].secure_url}
                            download={data.file[0].secure_url}
                          >
                            Download
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="message-data-client">
                      <span className="message-data-client-time">
                        {format(
                          new Date(data.createdAt),
                          "dd MMM, yyyy hh:mm a"
                        )}
                      </span>
                    </div>
                  </li>
                  //  there message end
                )
              )}
          </ul>
          {showPicker && (
            <div
              className="float-right"
              style={{
                zIndex: "7",
                position: "absolute",
                right: "10px",
                bottom: "50px",
              }}
            >
              <Picker
                pickerStyle={{ width: "100%", opacity: "0.1" }}
                onEmojiClick={onEmojiClick}
              />
            </div>
          )}

          {/* <div ref={chatEndRef} /> */}
        </div>

        <div className="chat-message-client clearfix-client">
          <div className="input-group mb-0">
            <div role="button" className="input-group-prepend">
              <span
                onClick={() => setShowPicker((val) => !val)}
                className="input-group-text"
              >
                <i class="bi bi-emoji-smile"></i>
              </span>
            </div>
            <div role="button" className="input-group-prepend">
              <span onClick={handleAttachFile} className="input-group-text">
                <i class="bi bi-file-earmark-arrow-up"></i>
              </span>
              <input
                ref={fileRef}
                type="file"
                onChange={handleImageChange}
                className="form-control-file d-none"
                id="exampleFormControlFile1"
                accept="image/*,.pdf"
              />
            </div>

            <input
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSend();
                }
              }}
              id="client-message-input"
              type="text"
              className="form-control"
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Enter text here..."
              value={newMessage}
            />
            {loading === true ? (
              <div className="input-group-prepend">
                <span className="spinner-border" role="status"></span>
              </div>
            ) : (
              <>
                <div role="button" className="input-group-prepend">
                  <span onClick={handleSend} className="input-group-text">
                    <i class="bi bi-send"></i>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentIssueMessage;
